"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClosestBusinessDay = exports.formatForDateControl = exports.addDays = exports.getDateWithTimezoneAdjusted = exports.getSpecificTimeZoneDateString = exports.utcToISODateString = exports.sortDatesAsc = exports.addOffsetToDate = void 0;
var date_fns_1 = require("date-fns");
exports.addOffsetToDate = function (date, offset) {
    if (date === undefined || date === null || date === "") {
        return date;
    }
    var asDate = new Date(date);
    try {
        //get difference between UTC and the current interpreted system date
        var calcOffset = asDate.getTimezoneOffset();
        var offsetDate = date_fns_1.addMinutes(asDate, offset ? offset * 60 : calcOffset);
        return offsetDate;
    }
    catch (e) {
        console.log("Date: " + date + ", asDate: " + new Date(date));
        console.log(e);
        return date;
    }
};
exports.sortDatesAsc = function (x, y) {
    var a = new Date(x).getTime(), b = new Date(y).getTime();
    return a - b;
};
exports.utcToISODateString = function (value) {
    if (value === "" || value === null || value === undefined) {
        return "";
    }
    var inputDate = typeof value === "string" ? new Date(value) : value;
    try {
        return inputDate.toISOString().slice(0, 10);
    }
    catch (ex) {
        return "";
    }
};
exports.getSpecificTimeZoneDateString = function (value, timeZone) {
    if (value === "" || value === null || value === undefined) {
        return "";
    }
    var inputDate = typeof value === "string" ? new Date(value) : value;
    try {
        return inputDate.toLocaleDateString("en-US", { timeZone: timeZone });
    }
    catch (ex) {
        return "";
    }
};
var pad = function (num) {
    var clean = Math.ceil(num);
    return clean < 10 ? "0" + clean : "" + clean;
};
//Generates a date that includes the current timezone offset so the final representation will be accurate in UTC.
exports.getDateWithTimezoneAdjusted = function (year, month0Indexed, day, hour, minute, seconds) {
    var offset = Math.ceil(new Date().getTimezoneOffset() / 60);
    var month = month0Indexed + 1;
    var newDateString = year + "-" + pad(month) + "-" + pad(day) + "T" + pad(hour !== null && hour !== void 0 ? hour : 0) + ":" + pad(minute !== null && minute !== void 0 ? minute : 0) + ":" + pad(seconds !== null && seconds !== void 0 ? seconds : 0) + ".000-" + pad(offset) + ":00";
    console.log("new datestring is: " + newDateString);
    return new Date(newDateString);
};
exports.addDays = function (value, days) {
    if (value === "" || value === null || value === undefined) {
        return null;
    }
    var inputDate = typeof value === "string" ? new Date(value) : value;
    var date = new Date(inputDate.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};
exports.formatForDateControl = function (date) {
    if (!date)
        return "";
    var _a = date.substr(0, 10).split("-"), year = _a[0], month = _a[1], day = _a[2];
    if (parseInt(year) > 999) {
        return date_fns_1.format(new Date(year, month - 1, day), "yyyy-MM-dd");
    }
    return date;
};
exports.getClosestBusinessDay = function (input) {
    var weekday = input.getDay();
    if (weekday === 0) {
        //Sunday - shift to Monday
        return date_fns_1.addDays(input, 1);
    }
    else if (weekday === 6) {
        //Saturday - shift to Friday
        return date_fns_1.subDays(input, 1);
    }
    return input;
};
