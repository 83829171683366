import { IDocument } from "oda-shared";
import { getSizeInKb } from "../utils/DocumentUtilities";
import format from "date-fns/format";
import { addOffsetToDate, getTimelessDateWithOffset } from "../utils/DateUtils";

const permitBaseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/permitApplication`;

export const permitAppUrl = (id: string, propertyName: string, documentType?: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/Document/PermitApplication/${id}/${propertyName}/${documentType}`;
};

export const tagReplacementUrl = (id: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/document/permit/${id}/tagReplacement`;
};

export const tagPictureUrl = (id: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/Document/Tag/${id}/picture`;
};

export const permitCancellationUrl = (id: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/Document/Permit/${id}/cancel`;
};

export const permitReinstateUrl = (id: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/Document/Permit/${id}/reinstate`;
};

export const documentPermitPictureUrl = (id: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/publicDocument/permitPicture/${id}`;
};

export const documentUrl = (id: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/Document/${id}`;
};

export const odaAccountUrl = (id: string, documentType: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/document/odaAccount/${id}/${documentType}`;
};

export const findingDocumentUrl = (id: string, permitId: string, documentType: string) =>{
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/document/finding/${id}/${permitId}/${documentType}`;
}

export const taskAssignmentUrl = (id: string, documentType: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/document/taskAssignment/${id}/${documentType}`;
};

export const violationUrl = (id: string, documentType: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/document/violation/${id}/${documentType}`;
};

export const vocDocumentUrl = (id: string, documentType: string, inspectionId: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/document/notice/${id}/${inspectionId}/${documentType}`;
};

export const permitDocumentUrl = (id: string, documentType: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/document/permit/${id}/${documentType}`;
};

export const licenseDocumentUrl = (id: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/document/license/${id}`;
};

export const illegalSignDocumentUrl = (id: string, documentType: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/document/illegalSign/${id}/${documentType}`;
};

export const litigationDocumentUrl = (id: string, documentType: string) =>
  `${fdot.process.env.BACKEND_SERVER_HOST}/api/Document/litigation/${id}/${documentType}`;

export const printLandUseDocumentUrl = (id: string) => `${permitBaseUrl}/print/landUseDoc/${id}`;
export const printLocalGovDocumentUrl = (id: string) => `${permitBaseUrl}/print/localGovDoc/${id}`;
export const printPermitAppDocumentUrl = (id: string) => `${permitBaseUrl}/print/completedPermitAppDoc/${id}`;
export const printPermitCancellationDocumentUrl = (id: string) => `${permitBaseUrl}/print/permitCancellationDoc/${id}`;
export const printPermitRevokedRemovalDocumentUrl = (id: string) =>
  `${permitBaseUrl}/print/permitRevokedRemovalDoc/${id}`;
export const printPermitNotRequiredLetterUrl = (id: string) =>
  `${fdot.process.env.BACKEND_SERVER_HOST}/api/permit/permitNotRequiredLetter/${id}`;
export const printIncompleteLetterUrl = (id: string, applyToAllFacings: boolean) =>
  `${fdot.process.env.BACKEND_SERVER_HOST}/api/permit/incompleteLetter/${id}/${applyToAllFacings}`;
export const printDenialLetterUrl = (id: string, applyToAllFacings: boolean) =>
  `${fdot.process.env.BACKEND_SERVER_HOST}/api/permit/denialLetter/${id}/${applyToAllFacings}`;

export const getItemizedBillingUrl = (id: string, cost: number) =>
  `${fdot.process.env.BACKEND_SERVER_HOST}/api/permitApplication/print/itemizedBilling/${id}/${cost}`;
export const getLicenseItemizedBillingUrl = (id: string, cost: number) =>
  `${fdot.process.env.BACKEND_SERVER_HOST}/api/licenseApplication/print/itemizedBilling/${id}/${cost}`;
export const sectionAppUrl = (id: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/Document/Section/${id}/documents`;
};
export const sectionAnnualDataAppUrl = (id: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/Document/SectionAnnualData/${id}/documents`;
};

export const permitRenewalSummary = (id: string, currentDate: Date | string | undefined | null) => {  
  let asDate = new Date(addOffsetToDate(currentDate) ?? new Date());    
  if (isNaN(asDate.getTime())){
    asDate = new Date();
  }
  const dateParam = format(asDate, "yyyy-MM-dd");
  return `${permitBaseUrl}/print/permitRenewalSummary/${id}/${dateParam}`;
}
  
export const permitRenewalSupplement = (id: string) => 
  `${permitBaseUrl}/print/permitRenewalSupplement/${id}`;

export const facingAppUrl = (id: string, propertyName: string, documentType?: string) => {
  return `${fdot.process.env.BACKEND_SERVER_HOST}/api/Document/Facing/${id}/${propertyName}/${documentType}`;
};

export const getDownloadableDocName = (doc: IDocument) => {
  if (!doc) {
    return "";
  }
  return `${doc.originalname} ${doc.documentType ? `- ${doc.documentType}` : ""} ${getSizeInKb(doc.blobSize)} ${
    doc.added ? new Date(doc.added).toLocaleDateString() : ""
  }`;
};
