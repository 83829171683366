"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatePayment = exports.calculateCost = exports.calculateCostForSinglePayItem = exports.calculateTransferPermitCost = exports.calculateTagReplacementCost = exports.calculateReinstatePermitCost = exports.calculatePermitRenewalCost = exports.permitRenewalCost = exports.expiredPermitSurcharge = exports.calculatePermitApplicationCost = exports.canRenewLicense = exports.calculateLicenseRenewalCost = exports.expiredLicenseSurcharge = exports.isLicenseExpired = exports.calculateLicenseCost = exports.canUnPayInvoice = exports.getPaymentQuarter = exports.getPermitCostByPaymentQuarter = exports.getPermitCostByDate = void 0;
var PaymentItemType_1 = require("../models/enums/payments/PaymentItemType");
//import { InternalRole } from "../models/enums/InternalRole";
var LicenseStatus_1 = require("../models/enums/LicenseStatus");
var PaymentType_1 = require("../models/enums/payments/PaymentType");
var PaymentQuarter_1 = require("../models/enums/payments/PaymentQuarter");
var PaymentStatus_1 = require("../models/enums/payments/PaymentStatus");
var DateUtils_1 = require("../utils/DateUtils");
var Rights = __importStar(require("../services/UserRightsService"));
var PermitRenewalService_1 = require("../services/PermitRenewalService");
//Moved to UserRightsService
// export const canProcessManualPayment = (user: IUser): boolean => {
//   if (user?.isExternal === true) {
//     return false;
//   }
//   const userRoles = user?.internalRoles || [];
//   const matches = userRoles.filter(i => rolesThatCanProcessPayments.indexOf(i) !== -1);
//   return matches.length > 0;
// };
exports.getPermitCostByDate = function (date) {
    var paymentQuarter = exports.getPaymentQuarter(date);
    return exports.getPermitCostByPaymentQuarter(paymentQuarter);
};
exports.getPermitCostByPaymentQuarter = function (paymentQuarter) {
    switch (paymentQuarter) {
        case PaymentQuarter_1.PaymentQuarter.FirstQuarter:
            return 71;
        case PaymentQuarter_1.PaymentQuarter.SecondQuarter:
            return 53.25;
        case PaymentQuarter_1.PaymentQuarter.ThirdQuarter:
            return 35.5;
        case PaymentQuarter_1.PaymentQuarter.FourthQuarter:
            return 88.75;
        default:
            throw new Error("Payment Quarter: " + paymentQuarter + " not recognized");
    }
};
exports.getPaymentQuarter = function (date) {
    var fullYear = date.getFullYear();
    if (date >= new Date(fullYear, 0, 16) && date <= new Date(fullYear, 3, 15, 23, 59, 59, 999)) {
        return PaymentQuarter_1.PaymentQuarter.FirstQuarter;
    }
    if (date >= new Date(fullYear, 3, 16) && date <= new Date(fullYear, 6, 15, 23, 59, 59, 999)) {
        return PaymentQuarter_1.PaymentQuarter.SecondQuarter;
    }
    if (date >= new Date(fullYear, 6, 16) && date <= new Date(fullYear, 8, 30, 23, 59, 59, 999)) {
        return PaymentQuarter_1.PaymentQuarter.ThirdQuarter;
    }
    if (date >= new Date(fullYear, 9, 1) || date <= new Date(fullYear, 0, 15, 23, 59, 59, 999)) {
        return PaymentQuarter_1.PaymentQuarter.FourthQuarter;
    }
    throw new Error("Date could not be parsed into valid Payment Quarter");
};
//Moved to UserRightsService
// export const canPayOnline = (user: IUser): boolean => {
//   return user?.isExternal === true;
// };
//Moved to UserRightsService
// export const rolesThatCanProcessPayments = [
//   InternalRole.FdotAdmin,
//   InternalRole.OfficeStaff,
//   InternalRole.OfficeSupervisor,
//   InternalRole.ManagementReviewer, //#52624 Management Reviewver has all roles of Office Supervisor
// ];
//Moved to UserRightsService
// const rolesThatCanUnPayInvoice = [
//   InternalRole.OfficeSupervisor,
//   InternalRole.ManagementReviewer, //#52624 Management Reviewver has all roles of Office Supervisor
// ];
//Moved to UserRightsService
// const canUserUnPayInvoice = (user: IUser) => {
//   for (const role of rolesThatCanUnPayInvoice) {
//     if (user.internalRoles.indexOf(role) !== -1) {
//       return true;
//     }
//   }
//   return false;
// };
exports.canUnPayInvoice = function (user, invoice) {
    if (!Rights.canUserUnPayInvoice(user)) {
        return false;
    }
    if (!invoice.payments) {
        return false;
    }
    var easternTimeZone = "America/New_York";
    var currentEasternDateString = DateUtils_1.getSpecificTimeZoneDateString(new Date(), easternTimeZone);
    var invoicePaidEasternDateString = DateUtils_1.getSpecificTimeZoneDateString(invoice.datePaid, easternTimeZone);
    console.log("Current Eastern Date String: " + currentEasternDateString);
    console.log("Invoice Paid Current Eastern Date String: " + invoicePaidEasternDateString);
    return currentEasternDateString.localeCompare(invoicePaidEasternDateString) === 0;
};
exports.calculateLicenseCost = function () {
    return 300;
};
exports.isLicenseExpired = function (license) {
    return new Date() > new Date(license.expirationDate);
};
exports.expiredLicenseSurcharge = 30;
exports.calculateLicenseRenewalCost = function (license) {
    var expired = exports.isLicenseExpired(license);
    return expired ? 300 + exports.expiredLicenseSurcharge : 300;
};
exports.canRenewLicense = function (license, currentDate, renewalDate) {
    var dateExpired = new Date(license.expirationDate);
    var renewal = new Date(renewalDate.renewalDate);
    console.log("Current Date:  " + currentDate);
    if (license.status === LicenseStatus_1.LicenseStatus.Active && dateExpired <= renewal) {
        return true;
    }
    else if (license.status === LicenseStatus_1.LicenseStatus.Expired &&
        currentDate >= new Date(dateExpired) &&
        PermitRenewalService_1.isWithinRenewalPeriod(renewalDate, currentDate.toDateString())) {
        return true;
    }
    return false;
};
exports.calculatePermitApplicationCost = function (paymentQuarter, calculationDate) {
    console.log("Passed in quarter is: " + paymentQuarter + ", date is " + calculationDate);
    var dateToUse = calculationDate ? new Date(calculationDate) : new Date();
    var quarter = paymentQuarter !== null && paymentQuarter !== void 0 ? paymentQuarter : exports.getPaymentQuarter(dateToUse);
    console.log("Using " + dateToUse + " to calculate, got " + quarter + " for payment quarter.");
    return exports.getPermitCostByPaymentQuarter(quarter);
};
exports.expiredPermitSurcharge = 7.1;
exports.permitRenewalCost = 71;
exports.calculatePermitRenewalCost = function (renewalDate, numberOfPermits) {
    var pastRenewalDate = new Date() > new Date(renewalDate);
    var totalCost = exports.permitRenewalCost * numberOfPermits;
    // 10% late fee if past renewal date
    return pastRenewalDate ? totalCost + exports.expiredPermitSurcharge * numberOfPermits : totalCost;
};
// export const calculateReinstatePermitCost = (): number => {
//   return 300 + calculatePermitRenewalCost(new Date(new Date().getFullYear() + 1, 0, 15), 1);
// };
exports.calculateReinstatePermitCost = function (renewalDate, numberOfPermits) {
    var permitCosts = exports.calculatePermitRenewalCost(new Date(renewalDate), numberOfPermits);
    var reinCosts = numberOfPermits * 300;
    return reinCosts + permitCosts;
};
exports.calculateTagReplacementCost = function () {
    return 12;
};
exports.calculateTransferPermitCost = function (numberOfPermits) {
    if (new Date().getMonth() === 11) {
        var transferPrice = numberOfPermits > 20 ? 100 : numberOfPermits * 5;
        return transferPrice + numberOfPermits * 70;
    }
    return numberOfPermits > 20 ? 100 : numberOfPermits * 5;
};
exports.calculateCostForSinglePayItem = function (paymentItem) {
    var _a, _b, _c, _d, _e;
    switch (paymentItem.itemType) {
        case PaymentItemType_1.PaymentItemType.License:
            return exports.calculateLicenseCost();
        case PaymentItemType_1.PaymentItemType.LicenseRenewal:
            if (!((_a = paymentItem.associatedEntity) === null || _a === void 0 ? void 0 : _a.license)) {
                throw new Error("You must pass in the license to this method as an associated entity");
            }
            return exports.calculateLicenseRenewalCost(paymentItem.associatedEntity.license);
        case PaymentItemType_1.PaymentItemType.PermitApplication:
            if (paymentItem.itemsId && ((_b = paymentItem.itemsId) === null || _b === void 0 ? void 0 : _b.length) !== 0) {
                return exports.calculatePermitApplicationCost(paymentItem.paymentQuarter) * paymentItem.itemsId.length;
            }
            return exports.calculatePermitApplicationCost(paymentItem.paymentQuarter);
        case PaymentItemType_1.PaymentItemType.PermitRenewal:
            if (!paymentItem.itemsId || paymentItem.itemsId.length === 0) {
                throw new Error("Permit Renewal Pay Item must contain item ids");
            }
            return exports.calculatePermitRenewalCost(paymentItem.renewalDate, (_c = paymentItem.itemsId) === null || _c === void 0 ? void 0 : _c.length);
        case PaymentItemType_1.PaymentItemType.ReinstatePermit:
            if (!paymentItem.itemsId || paymentItem.itemsId.length === 0) {
                throw new Error("Permit Reinstatement Pay Item must contain item ids");
            }
            // return calculateReinstatePermitCost();
            return exports.calculateReinstatePermitCost(paymentItem.renewalDate, paymentItem.itemsId.length);
        case PaymentItemType_1.PaymentItemType.TagReplacement:
            return exports.calculateTagReplacementCost();
        case PaymentItemType_1.PaymentItemType.TransferPermits:
            if (!((_d = paymentItem.associatedEntity) === null || _d === void 0 ? void 0 : _d.transferRequest)) {
                throw new Error("You must pass in the transferRequest to this method as an associated entity");
            }
            return exports.calculateTransferPermitCost(paymentItem.associatedEntity.transferRequest.permits.length);
        case PaymentItemType_1.PaymentItemType.Invoice:
            if (!((_e = paymentItem.associatedEntity) === null || _e === void 0 ? void 0 : _e.invoice)) {
                throw new Error("You must pass in the invoice to this method as an associated entity for payment type Invoice.");
            }
            return paymentItem.standardCost;
        case PaymentItemType_1.PaymentItemType.DelinquentFees:
            //We do not need to recalculate these...they are based on the renewal item they were generated from.
            return paymentItem.standardCost;
        default:
            throw new Error("No calculate cost function is defined for this Payment Item type: " + paymentItem.itemType);
    }
};
exports.calculateCost = function (paymentItems) {
    return paymentItems.reduce(function (sum, currentPayItem) { return sum + exports.calculateCostForSinglePayItem(currentPayItem); }, 0);
};
exports.validatePayment = function (payment) {
    var issues = [];
    if ((payment.paymentType !== PaymentType_1.PaymentType.Online && payment.accountId === undefined) || payment.accountId === null) {
        issues.push("This Payment must be Tied to an Account");
    }
    if (payment.paymentType === null) {
        issues.push("Instrument Type is Required");
    }
    else if (payment.paymentType === PaymentType_1.PaymentType.Check || payment.paymentType === PaymentType_1.PaymentType.MoneyOrder) {
        if (payment.instrumentNumber === null) {
            issues.push("Instrument Number is Required for Checks or Money Orders");
        }
        if (payment.payorFirstName === null && payment.payorLastName === null && payment.payorCompanyName === null) {
            issues.push("Payor Information is Required for Checks or Money Orders");
        }
        if (payment.instrumentExpiresInDays === null) {
            issues.push("Please enter the Number of Days Until the Payment Instrument (Check or Money Order) Expires");
        }
    }
    if (payment.amount === 0) {
        issues.push("Amount cannot be Zero");
    }
    if (payment.paymentStatus === null) {
        issues.push("Payment Status is Required");
    }
    if (payment.statusDate === null) {
        issues.push("Status Date is Required");
    }
    if (payment.paymentType === PaymentType_1.PaymentType.Online) {
        if (payment.paymentStatus === PaymentStatus_1.PaymentStatus.Received ||
            payment.paymentStatus === PaymentStatus_1.PaymentStatus.Deposited ||
            payment.paymentStatus === PaymentStatus_1.PaymentStatus.Returned) {
            issues.push("The status: " + payment.paymentStatus + " is not a valid payment status for an online payment.");
        }
    }
    else {
        if (payment.paymentStatus === PaymentStatus_1.PaymentStatus.Initiated ||
            payment.paymentStatus === PaymentStatus_1.PaymentStatus.Successful ||
            payment.paymentStatus === PaymentStatus_1.PaymentStatus.Duplicate ||
            payment.paymentStatus === PaymentStatus_1.PaymentStatus.Canceled) {
            issues.push("The status: " + payment.paymentStatus + " is not a valid payment status for a manual payment.");
        }
    }
    return issues;
};
