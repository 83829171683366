import { dateUtils } from "oda-shared";
import { format, addHours, addMinutes } from "date-fns";

export const getFiscalYearFromDate = (input: Date) => {
  const inputDate = new Date(input);
  const year = inputDate.getFullYear();

  const fiscalYearCutoff = new Date(year, 5, 30);

  if (inputDate > fiscalYearCutoff) {
    //first Half
    return `${year}-${year + 1}`;
  }
  //second Half
  else return `${year - 1}-${year}`;
};

export const inputFieldDateFormat = (date: Date | null | undefined): string => {
  return dateUtils.utcToISODateString(date);
};

export const formatNullableDate = (date: Date | string | null | undefined): string => {
  if (date === undefined || date === null) {
    return "N/A";
  }

  const asDate = typeof date === "string" ? new Date(date) : date;
  return format(asDate, "MM/dd/yyyy h:mm a");
};

export const removeTimeFromDate = (date: Date) => {    
  let val = new Date(date);
  val.setHours(0,0,0,0);  
  return val;
}

//Note the grid filters require a date with no time in order to filter appropriately.
export const getTimelessDateWithOffset  = (date: Date | string | null | undefined, offset?: number) => {
  const offsetDate = addOffsetToDate(date);
  if (!offsetDate){
    return date;
  }
  const asDate = new Date(offsetDate);
  return removeTimeFromDate(asDate);  
}

export const addOffsetToDate = (date: Date | string | null | undefined, offset?: number) => {
  return dateUtils.addOffsetToDate(date, offset);
  // if (date === undefined || date === null || date === "") {
  //   return date;
  // }    
  // const asDate = new Date(date);
  // try {    
  //   //get difference between UTC and the current interpreted system date
  //   const calcOffset = asDate.getTimezoneOffset();
  //   const offsetDate = addMinutes(asDate, offset ? offset * 60 : calcOffset);
  //   return offsetDate;
  // } catch (e) {
  //   console.log(`Date: ${date}, asDate: ${new Date(date)}`);    
  //   console.log(e);
  //   return date;
  // }
};

export const formatDateWithNoTimeZoneAddOffset = (date: Date | string | null | undefined, offset?: number): string => {
  if (date === undefined || date === null || date === "") {
    return "N/A";
  }    
  const asDate = new Date(date);
  try {    
    //get difference between UTC and the current interpreted system date
    const calcOffset = asDate.getTimezoneOffset();
    const offsetDate = addMinutes(asDate, offset ? offset * 60 : calcOffset);
    return format(offsetDate, "MM/dd/yyyy");
  } catch (e) {
    console.log(`Date: ${date}, asDate: ${new Date(date)}`);    
    console.log(e);
    return "N/A";
  }
};

export const formatDateWithNoTimeZone = (date: Date | string | null | undefined): string => {
  if (date === undefined || date === null || date === "") {
    return "N/A";
  }  
  const asDate = typeof date === "string" ? new Date(`${date}`) : date;  
  try {    
    return format(asDate, "MM/dd/yyyy");
  } catch (e) {
    console.log(`Date: ${date}, asDate: ${new Date(date)}`);    
    console.log(e);
    return "N/A";
  }
};
