import axios from "axios";
import { IShoppingCart, IShoppingCartItem } from "oda-shared";

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/shopping-cart`;

export const getShoppingCart = async (): Promise<IShoppingCart> => {
  const response = await axios.get(baseUrl);
  return response.data?.shoppingCart;
};

export const updateItem = async (item: IShoppingCartItem) => {
  const response = await axios.put(`${baseUrl}/items/${item._id}`, item);
  return response.data?.item;
};

export const addItem = async (item: IShoppingCartItem) => {
  console.log("addItem called");
  const response = await axios.post(`${baseUrl}/items`, item);
  return response.data?.items;
};

export const addRenewalItems = async(accountId: string, dateChanger: string | Date | undefined | null) => {
  console.log("Add renewals called");
  const response = await axios.post(`${baseUrl}/items/renewalItems/${accountId}`,{dateChanger: dateChanger});
  return response.data?.items;
}

export const deleteItem = async (id: string) => {
  debugger;
  const response = await axios.delete(`${baseUrl}/items/${id}`);
  return response.data?.items;
};

export const deleteRenewalItems = async(accountId: string) => {
console.log("Delete renewals called");
const response = await axios.delete(`${baseUrl}/items/renewalItems/${accountId}`);
return response.data?.items;
}