import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser, IUserProfile, Theme } from "oda-shared";
import * as userApi from "./../../apis/UserApi";

type SliceState = {
  isUserLoaded: boolean;
  isAuthenticated: boolean;
  isUserOnAnyAccount: boolean;  
  user?: IUser;
  theme?: Theme;
};

const userSlice = createSlice({
  name: "user",
  initialState: {
    isUserLoaded: false,
    isAuthenticated: false,
    isUserOnAnyAccount: false,
    user: undefined,
    theme: Theme.Standard,
  } as SliceState,
  reducers: {    
    updateUser: (state: SliceState, action: PayloadAction<IUser>) => {      
      state.user = action.payload;
      state.isAuthenticated =
        action.payload !== undefined &&
        action.payload !== null &&
        (action.payload.srsId !== undefined || action.payload.azureAdOid !== undefined);
      state.isUserLoaded = true;
      state.theme = action.payload.theme;      
      return state;
    },
    userLoaded: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isUserLoaded = action.payload;
      return state;
    },
    updateTheme: (state: SliceState, action: PayloadAction<Theme | undefined>) => {
      state.theme = action.payload;
      return state;
    },
    updateIsUserOnAnyAccount: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isUserOnAnyAccount = action.payload;
      return state;
    },
  },
});

export const { updateUser, userLoaded, updateTheme, updateIsUserOnAnyAccount } = userSlice.actions;
export default userSlice.reducer;

export const fetchIsUserOnAnyAccount = () => async (dispatch: any) => {
  userApi
    .getIsUserOnAnyAccount()
    .then((isAccountUser: boolean) => {
      dispatch(updateIsUserOnAnyAccount(isAccountUser));
      dispatch(userLoaded(true));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const fetchUser = () => async (dispatch: any) => {
  userApi
    .getUser()
    .then((user: IUser) => {
      dispatch(updateUser(user));
      dispatch(userLoaded(true));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const impersonateUser =
  (srsId?: number, successCallback?: () => void, failCallback?: () => void) => async (dispatch: any) => {
    if (srsId) {
      userApi
        .impersonateFdot(srsId)
        .then((user: IUser) => {
          dispatch(updateUser(user));
          if (successCallback) {
            successCallback();
          }
        })
        .catch((error: any) => {
          console.log(error);
          if (failCallback) {
            failCallback();
          }
        });
    }
  };

export const impersonateB2cUser =
  (azureAdOid?: string, successCallback?: () => void, failCallback?: () => void) => async (dispatch: any) => {
    if (azureAdOid) {
      userApi
        .impersonateB2c(azureAdOid)
        .then((user: IUser) => {
          dispatch(updateUser(user));
          if (successCallback) {
            successCallback();
          }
        })
        .catch((error: any) => {
          console.log(error);
          if (failCallback) {
            failCallback();
          }
        });
    }
  };

export const setUpdateDateChanger =
  (newDate: string, successCallback?: () => void, failCallback?: () => void) => async (dispatch: any) => {
    debugger;
    userApi
      .updateDateChanger(newDate)
      .then((user: IUser) => {
        dispatch(updateUser(user));
        if (successCallback) {
          successCallback();
        }
      })
      .catch((error: any) => {
        console.log(error);
        if (failCallback) {
          failCallback();
        }
      });
  };

export const changeTheme = (theme: Theme, azureAdOid: string) => async (dispatch: any) => {
  userApi
    .updateProfile({ theme: theme, azureAdOid } as any)
    .then((updatedProfile: IUserProfile) => {
      dispatch(updateTheme(updatedProfile.theme));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const fetchUserProfile = (azureAdOid: string) => async (dispatch: any) => {
  userApi
    .fetchProfile(azureAdOid)
    .then((profile: IUserProfile) => {
      dispatch(updateTheme(profile.theme));
    })
    .catch((error: any) => {
      console.log(error);
    });
};
